var render = function render(){var _vm=this,_c=_vm._self._c;return _c('content',[(_vm.loading)?_c('div',{staticClass:"text-center contmesa"},_vm._l((50),function(n){return _c('b-skeleton',{key:n,staticClass:"mesa",staticStyle:{"color":"#dedede !important","background-color":"#dedede !important"},attrs:{"width":"100","height":"100"}})}),1):_vm._e(),(_vm.view == 'mesas' && !_vm.layout && !_vm.loading)?_c('div',{staticClass:"contmesa"},_vm._l((_vm.ordened),function(mesa){return _c('mesas-geral-card',{key:`mmesa+${Math.random(444)}` + mesa.cod_mesa,class:{
        livre: mesa.dt_aberta == null,
        ocupada: mesa.dt_aberta != null,
        conta: mesa.dt_conta != null,
      },attrs:{"mesa":mesa},nativeOn:{"click":function($event){return _vm.openMesa(mesa)}}})}),1):_vm._e(),(_vm.$store.state.isMobile && !_vm.loading)?_c('div',{staticClass:"d-flex py-3 px-2 text-center w-100"},[_c('b-btn',{staticClass:"w-50 mr-1",attrs:{"variant":_vm.view == 'mesas' ? 'success' : 'secondary'},on:{"click":function($event){_vm.view = 'mesas'}}},[_c('img',{attrs:{"width":"25","src":require("@/assets/img/icones/table.png")}}),_vm._v(" MESAS ")]),_c('b-btn',{staticClass:"w-50 ml-1",attrs:{"variant":_vm.view == 'comandas' ? 'success' : 'secondary'},on:{"click":function($event){_vm.view = 'comandas'}}},[_c('i',{staticClass:"fa fa-barcode"}),_vm._v(" COMANDAS ")])],1):_vm._e(),(_vm.view == 'mesas' && _vm.layout && !_vm.loading)?_c('div',{staticClass:"contMesaMapa"},[_c('div',{staticClass:"text-left pl-3 d-flex justify-content-between",staticStyle:{"font-size":"4vw"}},[_c('span',[_c('b-icon-table'),_vm._v(" Mapa das Mesas")],1),_c('span',[_c('b-btn',{attrs:{"variant":"info"},on:{"click":_vm.findByComnanda}},[_c('i',{staticClass:"fa fa-barcode mr-1"}),_vm._v(" Buscar Comanda")])],1)]),_c('div',{staticClass:"salao"},_vm._l((_vm.layout.layout),function(row){return _c('div',{key:`row_${row.id}_${Math.random()}`,staticClass:"fila",style:(`width:${Math.floor(Math.abs(100 / _vm.layout.layout.length))}%`)},_vm._l((row.items),function(it){return _c('div',{key:`mesa_${row.index}_${it.cod_mesa}`,staticClass:"mesa d-flex flex-column justify-content-center",class:`${
            (_vm.VerificaMesaReunida(it) ? ' mesaJaUnida  ' : '') +
            ((it.id && it.juncao && it.juncao.length > 0) ||
            (!it.id && it.uniao)
              ? 'mesaUnida '
              : _vm.VerificaMesaReunida(it)
              ? ' mesaReunida '
              : '') +
            (it.obj && it.obj.dt_conta_solicitada != null
              ? ' mesaConta '
              : '') +
            (it.obj && it.obj.reservas && it.obj.reservas.length > 0
              ? ' mesaReservada'
              : '') +
            (it.obj && it.obj.dt_aberta == null ? ' mesaLivre ' : '') +
            (it.obj && it.obj.dt_aberta != null ? ' mesaOcupada ' : '')
          }`,on:{"click":function($event){return _vm.openMesa(it.obj)}}},[_vm._v(" "+_vm._s(it.title.replace("Mesa", ""))+" "),(it.obj && it.obj.reservas && it.obj.reservas.length > 0)?_c('div',{staticClass:"reserva"},[_c('b-icon-calendar-check',{style:(it.obj.reservas.some((x) => x.dt_confirmado)
                  ? 'color: lightgreen'
                  : '')})],1):_vm._e()])}),0)}),0)]):_vm._e(),(!_vm.loading && _vm.view == 'comandas')?_c('div',{staticClass:"p-4"},[_c('mesa-new-comandas-openeds',{attrs:{"isOpen":true,"mesas":_vm.mesas,"show-open-comanda":true},on:{"comanda":_vm.selectComanda,"openComanda":_vm.openNewComanda}})],1):_vm._e(),_c('b-modal',{attrs:{"id":"modal-mesa","title":`${_vm.mesaSelected ? _vm.mesaSelected.mesa : ''}`,"size":"xl","hide-footer":"","modal-class":_vm.$store.state.isMobile ? `modal-full p-0 ` : 'modal-full p-0',"body-class":`p-0`,"content-class":`p-0 ${_vm.$store.state.isMobile ? `fixed` : 'fixed'}`,"hide-header-close":_vm.$store.state.isMobile,"hide-header":_vm.$store.state.isMobile},on:{"hide":function($event){return _vm.closeMesa()}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{on:{"click":_vm.closeMesa}},[(_vm.$store.state.isMobile)?_c('span',[_c('b-icon-chevron-left')],1):_vm._e(),_vm._v(" "+_vm._s(`${_vm.mesaSelected ? _vm.mesaSelected.mesa : ""}`)+" ")])]},proxy:true}])},[_c('mesa-new',{attrs:{"mesa":_vm.mesaSelected,"data":_vm.data,"empresa":_vm.empresa,"mesas":_vm.mesas,"mesaLayout":_vm.layout,"comandaSelected":_vm.comandaSelected},on:{"closeMesa":_vm.closeMesa,"updateMesa":(mesa) => _vm.$emit('updateMesa', mesa),"mesaFechada":_vm.closeMesa,"changeMesa":_vm.openMesa}})],1),_c('b-modal',{attrs:{"id":"modal-find-comanda","hide-footer":""}},[_c('mesa-new-comandas-openeds',{attrs:{"mesas":_vm.mesas},on:{"comanda":_vm.selectComanda}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }