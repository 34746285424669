<template>
  <div class="mesa">
    <img src="@/assets/img/icones/table.png" />
    <div class="titulo">
      {{ mesa.mesa }}
      <div
        v-if="dtAberta && dtAberta.trim() != ''"
        style="font-size: 10px"
      >
        {{ dtAberta }}
      </div>
    </div>

    <div
      class="alerta"
      v-if="mesa.conta_solicitada === 1"
    >
      <b-icon-calculator />
    </div>
    <div
      class="alerta alertaEsquerda"
      v-if="mesa.garcom_solicitado === 1"
    >
      <b-icon-person />
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  props: {
    mesa: Object,
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    dtAberta() {
      return this.mesa.dt_aberta
        ? moment().format("DD/MM/YYYY") ==
          moment(this.mesa.dt_aberta).format("DD/MM/YYYY")
          ? ""
          : moment(this.mesa.dt_aberta).format("DD/MM/YYYY")
        : "";
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mesas.scss";
</style>
